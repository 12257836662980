<template>
  <div class="newsDetailsBg">
      <div class="newsDetailsWd">
        <el-card>
          <div>
            <p style="font-size: 22px">{{newsDetail.title}}</p>
  <!--          <p>来源：{{newsDetail.endTime}}</p>-->
          </div>
          <div class="newsDetailTop">
              <p v-html="newsDetailContent.content"></p>
          </div>
        </el-card>
      </div>
  </div>
</template>
<script>
import {findContent} from "@/api";

export default {
  data(){
    return{
      newsDetail:{},
      newsDetailContent:{}
    }
  },
  methods:{
    //  获取新闻详情
    getfindContent(){
      let data={
        id:this.newsDetail.id
      }
      findContent(data).then(res=>{
        this.newsDetailContent=res.data
        this.newsDetailContent.glanceOvers=res.data.glanceOver
      })
    }
  },
  mounted(){
    document.documentElement.scrollTop = 0;
    this.newsDetail=JSON.parse(sessionStorage.getItem('newsDetail'));
    this.getfindContent()
  }
}
</script>
<style scoped>
@import "../../../public/css/newsDetail.css";
</style>
